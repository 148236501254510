<template>
  <div class="upcoming-events">
    <div
      class="d-flex justify-content-center"
    >
      <div class="upcoming-events-headers">
        <div class="d-flex flex-column justify-content-between align-items-center">
          <div class="d-flex justify-content-center mt-3 mb-2 flex-column align-items-center">
            <h1 class="text-center" style="font-weight: bold; font-size: 25px; color: #120088;">UPCOMING EVENTS</h1>
            <div
                class="text-center"
                style="border-bottom: 5px solid #031989; height: 4px; width: 100px;"
              ></div>
          </div>

          <b-card-text class="mb-5 text-center" style="font-size: 16px;">
            Click from the list below to view the upcoming PENSA Ghana Events
          </b-card-text>
        </div>
      </div>
      
    </div>
    <div class="d-flex align-items-center justify-content-center flex-wrap">
      <div
        v-for="(event, index) in events"
        :key="index"
        class="mx-3"
      >
        <div class="event-card-wrapper d-flex position-relative">
          <div class="blue-card"></div>

          <div class="event-info">
            <h1 class="event-title">{{ event.title }}</h1>
            <h4 class="event-date">{{ formatDuration(event.event_start_date, event.event_end_date) }}</h4>
            <b-card-text class="event-description">For more information click on the button below</b-card-text>
            <b-button class="mt-1" style="width: 80px" variant="primary" :to="{ name: 'single-event', params: { id: event._id } }">
              More
            </b-button>
          </div>
          <b-img
            :src="getActualValueFromSource(
                event,
                'thumbnail_image.path',
                require('@/assets/images/pages/blog-default.webp')
              )"
            class="evt-thumbs"
            style="position: absolute; width: 180px; height: 180px; top: -18px; left: -37px; border-radius: 20px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BRow, BCol, BImg, BButton, BCardText } from "bootstrap-vue";

import PublicEventCard from "./PublicEventCard.vue";
import 'swiper/css/swiper.css'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    BCardText,
    PublicEventCard,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    scrollEvents(to) {
      const { eventsContainer } = this.$refs;
      const containerDimensions = eventsContainer.getBoundingClientRect()
      const containerWidth = containerDimensions.width;
      const by = to === "left" ? -containerWidth : containerWidth
      eventsContainer.scrollLeft += by
    }
  }
};
</script>

<style lang="scss">

.event-card-wrapper {
  width: 300px;
  height: 210px;

  .blue-card {
    background-color: #0f00ad;
    width: 50%;
  }
  .event-info {
    width: 50%;
    padding-left: 5px;

    .event-title {
      font-size: 16px;
      font-weight: bold;
      color: #0f00ad;
    }

    .event-date {
      text-transform: uppercase;
      font-weight: bold;
      color: #4c4c4c;
      margin: 0px;
      font-size: 12px;
    }

    .event-month {
      text-transform: uppercase;
      font-weight: bold;
      color: #4c4c4c;
      font-size: 16px;
    }
  }
}

.events-container {
  height: 250px;
}

.events-container::-webkit-scrollbar {
  display: none;
}
.upcoming-events {
  padding-bottom: 50px;
  background-color: #f6f6f6;
}
</style>
