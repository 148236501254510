<template>
  <div>
    <div class="d-none d-md-block">
      <div class="d-flex justify-content-center" style="margin-bottom: 100px; height: 340px; background-color: #031988; padding: 20px;">
        <div>
          <h1 style="font-size: 5rem; color: white; font-weight: 900;">Grounded for Impact</h1>
          <div class="d-flex">
            <feather-icon icon="ChevronRightIcon" size="50" style="color: white;" />
            <div>
              <div style="color: white; font-size: 1.475rem; ">
                Our impact is felt when we have the right stand <br />
                with God. Through a daily communication with Christ, <br />
                our weapon as christians are made stronger to <br /> 
                fight back structures that raises itself against <br />
                God and Hist people.
              </div>
      
              <p class="text-yellow" style="color: yellow; font-size: 1.5rem;">By: Elder Emmanuel Amful</p>
            </div>
          </div>
        </div>
  
        <div style="background-color: #edaa04; max-height: 150px; border-radius: 10px; padding: 15px; position: relative; right: 10%; top: 80%">
          <h1 style="color: white; font-weight: 900; font-size: 2rem;">REPOSITIONING PILL</h1>
          <div style="color: #031988; font-size: large;">
            It takes also our efforts <br />
            in refining God <br />
            purpose in our lives.
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-3 mb-2 flex-column align-items-center">
      <h1 class="text-center" style="font-weight: bold; font-size: 25px; color: #120088;">OUR GALLERY</h1>
      <div
          class="text-center"
          style="border-bottom: 5px solid #031989; height: 4px; width: 100px;"
        ></div>
    </div>

    <swiper
      class="swiper-multiple"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(data, index) in galleries"
        :key="index"
      >
        <b-img
          :src="data.img"
          fluid
        />
      </swiper-slide>

      <!-- Add Arrows -->
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
      <div
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { BRow, BCol, BImg, BButton } from "bootstrap-vue";

import 'swiper/css/swiper.css'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,

    Swiper,
    SwiperSlide
  },
  data() {
    const galleries = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 20; i++){
      galleries.push({
        img: `/gallery/${i + 1}.jpg`
      })
    }
    return {
      galleries,
      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  }
};
</script>

<style scoped>
.side-note {
  position: relative;
  bottom: 20%;
  left: 60%;
  background-color: #031989;
  color: white;
  width: 240px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  font-weight: bolder;
}

.side-note-2 {
  position: relative;
  bottom: 52%;
  left: 135%;
  font-size: medium;
  /* font-size: large; */
  /* background-color: #031989;
    color: white;
    width: 240px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    font-weight: bolder; */
}
</style>
