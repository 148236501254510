<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      img-width="1024"
      img-height="400"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <!-- // eslint-disable-next-line vue/html-quotes -->
      <b-carousel-slide img-src="@/assets/images/public/slider/1.png" />
      <b-carousel-slide img-src="@/assets/images/public/slider/2.png" />
      <b-carousel-slide img-src="@/assets/images/public/slider/3.png" />
    </b-carousel>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide, BImg } from "bootstrap-vue"

export default {
  components: {
    BImg,
    BCarousel,
    BCarouselSlide
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ctx-overlay {
  position: absolute;
  background: rgba(22, 25, 68, 0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 15%;
  padding-top: 12%;
  h1 {
    font-size: 60px;
    color: white;
    font-weight: bold;
  }
  p {
    color: white;
    font-size: 16px;
    width: 500px;
  }

   @media (max-width: 576px) {
    display: none;
  }
}

</style>
