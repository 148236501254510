<template>
  <section class="mt-2 pb-5" style="max-width: 350px;">
    <div class="d-flex" style="gap: 5px;">
      <b-img
        width="160"
        height="170"
        style="border-radius: 10px;"
        :src="
          getActualValueFromSource(
            event,
            'thumbnail_image.path',
            require('@/assets/images/pages/blog-default.webp')
          )
        "
      />
      <div style="display: flex; flex-direction: column; justify-content: space-between;">
        <h3 class="text-primary -ellipsis-2" :title="event.title">
          {{ event.title }}
        </h3>
        <p class="-ellipsis-3" :title="event.excerpt">
          {{ event.excerpt }}
        </p>
        <h3 class="text-primary">
          {{ formatDuration(event.event_start_date, event.event_end_date) }}
        </h3>

        <b-button style="width: 80px" variant="primary" :to="{ name: 'single-event', params: { id: event._id } }">
          More
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BBadge,
  BCardImg,
  BImg,
  BOverlay,
  BPagination,
  BButton
} from "bootstrap-vue";
import Search from "@/assets/svg/Search.svg";

const watchHandler = {
  handler() {
    this.fetchAllData();
  },
  immediate: false,
};

const watchHandlerRequiresPageReset = {
  handler() {
    this.currentPage = 1;
    this.fetchAllData();
  },
  immediate: false,
};

export default {
  name: "ListBlogs",
  components: {
    BOverlay,
    BRow,
    BCard,
    BCol,
    BBadge,
    BCardImg,
    BImg,
    Search,
    BPagination,
    BButton
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      blogs: [],
      perPage: 1,

      debounceFetchAllData: null,
    };
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    },
  },
  watch: {
    perPage: watchHandlerRequiresPageReset,
    currentPage: watchHandler,
  },
  methods: {

  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";

.blog-title- {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-content- {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
}
</style>
