<template>
  <div class="d-flex justify-content-center" style="max-height: 400px; margin-bottom: 120px;">
    <div>
      <div class="d-flex justify-content-center my-3 flex-column align-items-center">
        <h1 class="text-center" style="font-weight: bold; font-size: 25px; color: #120088;">ABOUT US</h1>
        <div
            class="text-center mb-1"
            style="border-bottom: 5px solid #031989; height: 4px; width: 100px;"
          ></div>
      </div>
      
      <div class="d-flex" style="gap: 10px">
        <div>
          <b-img fluid width="350" height="300" src="@/assets/images/pages/hero-4.jpg" />
          <div class="side-note">
            <h1 style="color: white; font-weight: 900; font-size: 30px;">CAMPUS TAKE OVER</h1>
            <p>We are in 250 schools Across Ghana, both tertiary and SHS Schools</p>
          </div>

          <div class="side-note-2">
            <div>
              We go with the slogan <br />
              <span style="font-weight: 900;">&ldquo; Christ in you!! The hope of glory &rdquo;</span>
            </div>

            <b-button variant="primary" :to="{ name: 'about-us' }">Read More</b-button>
          </div>
        </div>
        
        <div>
          <h1>Who we are</h1>
          <div class="text-black" style="font-size: large;">
            <span class="font-weight-bold">PENSA</span> is a vibrant student wing <br />of The Church of Pentecost Youth Ministry.
            <br />Present in almost all tertiary and second cycle schools in Ghana,<br /> we are strategically positioned to holistically empower young people to be <br />impactful agents of transformation in all sphere of life they may find themselves.
          </div>
        </div>
      </div>
  

    </div>
  </div>
</template>
<script>
import { BRow, BCol, BImg, BButton } from "bootstrap-vue"

export default {
  components: {
    BRow, BCol, BImg, BButton
  }
};
</script>

<style scoped>
.side-note {
  position: relative;
  bottom: 10%;
  left: 42%;
  background-color: #031989;
  color: white;
  width: 240px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  font-weight: bolder;
}

.side-note-2 {
  position: relative;
  bottom: 45%;
  left: 115%;
  font-size: medium;
}
</style>
