<template>
  <div>
    <AppHero />

    <div class="d-none d-md-block">
      <div class="d-flex d-sm-flex-column justify-content-center align-items-center" style="background-color: #031989; height: 100px; gap: 20px;">
        <b-button :to="{ name: 'auth-login' }" size="lg" style="background-color: #e3ab00 !important;">
          LOGIN
        </b-button>
        <b-button :to="{ name: 'auth-register' }" size="lg" style="background-color: #0c9ef4 !important;">
          SIGN UP
        </b-button>
      </div>
    </div>

    <div class="d-none d-md-block">
      <AppAboutUs />
    </div>

    <b-overlay :show="loading" class="m-0 p-0">
      <AppUpcomingEvents :events="events" />
    </b-overlay>

    <div class="">
      <AppImpact />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
  BOverlay,
  BButton
} from 'bootstrap-vue';

import { get } from "lodash";

import ListEvents from "@/@core/components/shared/events/ListEvents.vue"
import AppHero from "@/@core/components/app/AppHero.vue"
import AppAboutUs from "@/@core/components/app/AppAboutUs.vue"
import AppImpact from "@/@core/components/app/AppImpact.vue"
import AppUpcomingEvents from "@/@core/components/app/AppUpcomingEvents.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BOverlay,
    BButton,

    ListEvents,
    AppHero,
    AppAboutUs,
    AppImpact,
    AppUpcomingEvents
  },
  data() {
    return {
      loading: false,
      events: [],
    }
  },
  created() {
    this.fetchAllData();
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: 3,
          page: this.currentPage,
        }

        const request = await this.useJwt().sharedService.fetchEvents(query);
        const { data } = request.data;

        this.events = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/app-home.scss';
.up-label {
  border-bottom: 10px solid rgb(2, 2, 119);
  padding-bottom: 10px;
  text-transform: uppercase;
}
</style>
